<template>
  <div>
    <!-- 密码 弹窗 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" v-if="$store.state.userInfo"
      :visible.sync="pwdVisible" :before-close="pwdModalClose" custom-class="custom-dialog modal-change">
      <div class="custom-dialog-head" slot="title">
        <div class="header__title">
          <h1 v-if="$store.state.userInfo?.withdrawal_psd">{{ $t("更改密码") }}</h1>
          <h1 v-else>{{ $t("设置密码") }}</h1>
        </div>
      </div>
      <el-form ref="form" :model="pwdform" label-position="top">
        <el-form-item v-if="$store.state.userInfo?.withdrawal_psd" :label="$t('旧密码')">
          <payPasswordInput v-model="pwdform.newPwd"></payPasswordInput>
        </el-form-item>
        <el-form-item :label="$t('输入密码')">
          <payPasswordInput v-model="pwdform.comfirmNewPwd"></payPasswordInput>
        </el-form-item>
        <el-form-item :label="$t('重复输入密码')">
          <payPasswordInput v-model="pwdform.curPassword"></payPasswordInput>
        </el-form-item>
        <div class="link" @click="resetPassword" v-if="$store.state.userInfo?.withdrawal_psd">{{ $t("忘记提现密码？") }}</div>
        <el-form-item>
          <el-button class="button_fullwidth button_red" :loading="$store.state.bottonLoading"
            @click="pwdSubmit(1)">{{
              $t("确认") }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <verify ref="verify" @enter="verifyEnter"></verify>
  </div>
</template>

<script>
import { my_pay_password_api } from "@/api/user";
import verify from "@/components/verify.vue";
import svgIcon from "@/components/svg.vue";
import payPasswordInput from "@/components/payPasswordInput.vue";
export default {
  data() {
    return {
      pwdVisible: false,
      verify_code: "",
      type: 1,
      pwdform: {
        newPwd: "",
        comfirmNewPwd: "",
        curPassword: "",
      },
    };
  },
  components: { verify, svgIcon, payPasswordInput },
  methods: {
    resetPassword() {
      this.$confirm(this.$t('重置后提现密码将清空，是否立即重置提现密码？'), this.$t('重置提现密码'), {
        confirmButtonText: this.$t('确认重置'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.type = 2
        this.$refs.verify.show("pay_passwd_verify", 120);
      });
    },
    handleClickPassword(e) {
      let newPassword = document.getElementById(e.target.id)
      newPassword.removeAttribute('readonly')
      newPassword.focus()
    },
    showPwdModal() {
      this.pwdVisible = true;
      this.pwdform.newPwd = "";
      this.pwdform.comfirmNewPwd = "";
      this.pwdform.curPassword = "";
    },
    pwdModalClose(done) {
      if (
        this.$route.query.modal === "paypwd" ||
        this.$route.query.modal === "loginpwd"
      ) {
        window.location.href = window.location.href
          .replace("?modal=paypwd", "")
          .replace("&modal=paypwd", "")
          .replace("?modal=loginpwd", "")
          .replace("&modal=loginpwd", "");
      }
      done();
    },
    testForm() {
      if (this.$store.state.userInfo?.withdrawal_psd && this.pwdform.newPwd == "") {
        this.errorTips("请输入旧密码");
        return false;
      }
      if (this.pwdform.comfirmNewPwd == "") {
        this.errorTips("请输入密码");
        return false;
      }
      if (this.pwdform.curPassword == "") {
        this.errorTips("请输入重复密码");
        return false;
      }
      if (this.pwdform.comfirmNewPwd.length < 6) {
        this.errorTips("密码长度不能低于6位字符");
        return false;
      }
      if (this.pwdform.comfirmNewPwd != this.pwdform.curPassword) {
        this.errorTips("密码不一致");
        return false;
      }
      return true
    },
    async pwdSubmit(type) {
      if(type) this.type = type
      if (this.type === 1 && !this.testForm()) {
        return
      }
      this.$store.commit("$vuexSetBottonLoadingState", true);
      let param = {
        opassword: this.pwdform.newPwd,
        password: this.pwdform.comfirmNewPwd,
        type: this.type,
        verify_code: this.verify_code,
      };
      await my_pay_password_api(param).then((res) => {
        if (res && res.data.code == 1) {
          this.updateUserInfo();
          this.pwdform.newPwd = "";
          this.pwdform.comfirmNewPwd = "";
          this.pwdform.curPassword = "";
          this.pwdVisible = false;
          this.successTips(res.data.msg);
          this.verifyClose();
        } else if (
          res &&
          (res.data.code == 110 || res.data.code == 120 || res.data.code == 130)
        ) {
          this.verify_code && this.errorTips(res.data.msg);
          this.verifyShow("pay_passwd_verify", res.data.code);
        } else {
          this.verifyClose();
          this.errorTips(res.data.msg);
        }
        this.$store.commit("$vuexSetBottonLoadingState", false);
      });
    },
    verifyShow(type, code) {
      this.verify_code = "";
      this.$refs.verify.show(type, code);
    },
    verifyClose() {
      this.verify_code = "";
      this.$refs.verify.close();
    },
    verifyEnter(code, type) {
      this.verify_code = code;
      if (type === "pay_passwd_verify") {
        this.verify_code && this.pwdSubmit();
      }
    },
  },
};
</script>

<style scoped>
.button_red {
  margin-top: 20px;
}

.link {
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
</style>